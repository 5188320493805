<template>
    <v-row>
        <v-col
        cols="12"
        md="12">
            <table-parcelas
            :empresa="this.empresa"
            :num_venda="this.num_venda"
            :obra="this.obra"
            :empreendimento="this.empreendimento"
            :identificador="this.identificador"></table-parcelas>
        </v-col>
    </v-row>
</template>

<script>
import TableParcelas from './TableParcelas.vue';

export default {
    props: {
        empresa: '',
        num_venda: '',
        obra: '',
        empreendimento: '',
        identificador: ''
    },
    mounted: function(){
    },
    components: {
        TableParcelas,
    },
    data(){
        return {
        }
    },
    setup() {
        
    },
}
</script>