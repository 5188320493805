<template>
    <div>

        <template v-if="this.mensagemAviso">
            <a class="text-decoration-none" @click="$router.go(-1)">
                <v-icon large color="red">
                    {{ icons.mdiArrowLeftBoldCircleOutline }}
                </v-icon>
            </a>
        </template>
        <v-row class="mt-5 ml-4" v-if="this.mensagemAviso">
            <h3>Não foi possível listar as parcelas, tente novamente mais tarde.</h3>
        </v-row>

        <v-card v-if="!this.mensagemAviso">
            <v-card-title>
                <v-icon>
                    {{ icons.mdiFinance }}
                </v-icon>
                <span class="me-3">&nbsp; Parcelas</span>
                <v-spacer></v-spacer>
                <span class="text-xs cursor-pointer" style="color: red;">* Atenção: Valor da parcela referente à
                    antecipação</span>
            </v-card-title>

            <v-data-table v-if="this.showTable" :headers="headers" :items="this.parcelas" :items-per-page="10"
                :sort-by="['numero']" item-key="ind" class="table-rounded" fixed-header :footer-props="{
                    showFirstLastPage: true,
                    'items-per-page-text': 'Parcelas por página',
                    'items-per-page-options': [10, 20, 30, 40, 50]
                }" no-data-text="Nenhuma parcela encontrada" no-results-text="Nenhuma parcela encontrada">
                <!-- idx -->
                <template #[`item.id`]="{ item }">
                    <v-icon>
                        {{ icons.mdiOfficeBuildingMarkerOutline }}
                    </v-icon>
                    <div class="d-flex flex-column">
                        <small>{{ item.post }}</small>
                    </div>
                </template>

                <!-- Vencimento -->
                <template #[`item.vencimento`]="{ item }">
                    <v-chip small color="red darken-1" outlined class="font-weight-medium">
                        {{ item.vencimento }}
                    </v-chip>
                </template>

                <!-- Vencimento -->
                <template #[`item.recebidoem`]="{ item }">
                    <v-chip v-if="item.recebidoem != ''" small color="cyan" outlined class="font-weight-medium">
                        {{ item.recebidoem }}
                    </v-chip>
                </template>

                <!-- Valor -->
                <template #[`item.valor`]="{ item }">
                    <v-chip small color="teal" class="font-weight-medium">
                        {{ item.valor }}
                    </v-chip>
                </template>

                <!-- status -->
                <template #[`item.status`]="{ item }">
                    <v-chip small :color="statusColor[status[item.status]]" class="font-weight-medium">
                        {{ status[item.status] }}
                    </v-chip>
                </template>

                <!-- Ação -->
                <template #[`item.acoes`]="{ item }">
                    <v-btn style="height: 20px !important;width: 90px !important;" small icon color="white"
                        class="btn-bol text-center">
                        <small>Parcelas</small>
                        <small style="visibility: hidden; position: absolute;">{{ item.acoes }}</small>
                    </v-btn>
                </template>

            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import { mdiArrowLeftBoldCircleOutline, mdiFinance, mdiOfficeBuildingMarkerOutline, mdiEyeOutline } from '@mdi/js'

import Cookie from 'js-cookie'
import moment from 'moment'
import UsuarioStore from './../../store/Usuario'
import Strings from '@/common/strings'

export default {
    props: {
        empresa: '',
        num_venda: '',
        obra: '',
        empreendimento: '',
        identificador: ''
    },
    mounted: function () {

        this.$loading(true)

        if (this.empresa == '' || this.num_venda == '' || this.obra == ''
            || typeof this.empresa == 'undefined' || typeof this.num_venda == 'undefined' || typeof this.obra == 'undefined') {
            this.mensagemAviso = true
            this.$loading(false)
            return
        }

        var payload = {
            empresa: this.empresa,
            num_venda: this.num_venda,
            obra: this.obra
        }

        UsuarioStore.parcelasDetalhado(payload, Strings.imobiliariaId).then(result => {

            var numeroParcela = 1

            result.data.parcelasPagas.Recebidas.forEach((val, ind) => {

                let valorParcelaReajustada = val.ValorConf_Rec + val.VlCorrecaoConf_Rec + val.VlMultaConf_Rec + val.VlJurosConf_Rec - val.VlDescontoConf_Rec

                var json = {
                    responsive_id: '',
                    id: ind,
                    numero: numeroParcela++,
                    empreendimento: this.empreendimento,
                    identificador: this.identificador,
                    descricao: '',
                    numeroRef: val.NumParc_Rec,
                    status: 1,
                    vencimento: moment(String(val.DataVenci_Rec)).format('DD/MM/YYYY'),
                    recebidoem: moment(String(val.Data_Rec)).format('DD/MM/YYYY'),
                    valor: valorParcelaReajustada.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    })
                }

                this.parcelas.push(json)
            })

            result.data.parcelasAReceber.forEach((val, ind) => {

                let tipStatus = 3
                if (moment(val.Data_Prc).isBefore()) {
                    tipStatus = 2
                }

                var json = {
                    responsive_id: '',
                    id: ind,
                    empreendimento: this.empreendimento,
                    identificador: this.identificador,
                    descricao: val.Descr_ctp,
                    numero: numeroParcela++,
                    numeroRef: val.NumParc_Prc,
                    status: tipStatus,
                    vencimento: moment(String(val.Data_Prc)).format('DD/MM/YYYY'),
                    recebidoem: '',
                    valor: val.ValorReaj.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    })
                }

                this.parcelas.push(json)
            })

            this.showTable = true

        }).catch(error => {

            console.log(error)
            if (error.response.status == 401) {
                Cookie.remove(Strings.cookie_token)
                Cookie.remove(Strings.cookie_username)
                this.$router.push('/pages/login')
            }
        }).finally(() => {
            this.$loading(false)
        })
    },
    data() {
        return {
            mensagemAviso: false
        }
    },
    computed: {
        headers() {
            const baseHeaders = [
                { text: '#', value: 'id', show: true },
                { text: 'Nº', value: 'numero', show: true },
                { text: 'Empreendimento', value: 'empreendimento', show: true },
                { text: 'Identificador', value: 'identificador', show: true },
                { text: 'Descrição', value: 'descricao', show: true },
                { text: 'Nº Referência', value: 'numeroRef', show: true },
                { text: 'Status', value: 'status', show: true },
                { text: 'Vencimento', value: 'vencimento', show: true },
                { text: 'Recebido em', value: 'recebidoem', show: true },
            ];

            // Adiciona a coluna 'Valor' condicionalmente
            if (!Strings.colunm_show_value_parcela || Strings.colunm_show_value_parcela === 'true') {
                baseHeaders.push({ text: 'Valor', value: 'valor', show: true });
            }

            return baseHeaders;
        }
    },
    setup() {
        const parcelas = []
        const showTable = false
        const statusColor = {
            Avencer: 'blue',
            Pago: 'success',
            Vencida: 'error',
        }

        return {
            status: {
                1: 'Pago',
                2: 'Vencida',
                3: 'Avencer',
            },
            statusColor,

            // icons
            icons: {
                mdiFinance,
                mdiOfficeBuildingMarkerOutline,
                mdiEyeOutline,
                mdiArrowLeftBoldCircleOutline
            },
            parcelas,
            showTable
        }
    },
}
</script>
<style scoped>
.btn-bol {
    border-radius: 40px !important;
    width: 5rem !important;
    background: rgb(0, 136, 255);
    height: 1.6rem !important;
}
</style>